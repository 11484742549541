<template>
  <validation-observer ref="validator" slim disabled>
    <v-container fluid class="pa-0">
      <v-row class="flex-wrap">
        <v-col cols="12">
          <validation-provider slim :name="$t('global.name')" rules="required|max:255" v-slot="{ errors }">
            <v-text-field
              v-model="innerValue.name"
              :label="$t('global.name')"
              clearable
              counter="255"
              color="tertiary"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="6">
          <validation-provider
            slim
            :name="$t('hr.expense.expense_type.expense_type')"
            rules="required"
            v-slot="{ errors }"
          >
            <expense-type-picker
              v-model="innerValue.expenseTypeObject"
              return-object
              :label="$t('hr.expense.expense_type.expense_type')"
              clearable
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="6">
          <v-row class="flex-wrap justify-center align-start pa-0">
            <v-col cols="12" sm="7" class="pa-0 pr-2">
              <validation-provider
                slim
                :name="$t('currency.amount')"
                rules="required|min_value: 0|max_value: 999999999"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model.number="innerValue.requested.amount"
                  v-decimal
                  :label="$t('currency.amount')"
                  color="tertiary"
                  clearable
                  :min="0"
                  :max="999999999"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="5" class="pa-0">
              <validation-provider slim :name="$t('currency.code')" rules="required" v-slot="{ errors }">
                <currency-picker
                  v-model="innerValue.requested.code"
                  clearable
                  :disabled="isCurrencyCodeDisabled"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="innerValue.paymentType"
            :label="$t('global.paymentType')"
            :items="paymentTypes"
            :menu-props="{ offsetY: true }"
            :item-text="item => $t(`hr.expense.payment_type.${item.text}`)"
            item-value="value"
            color="tertiary"
          />
        </v-col>
        <v-col cols="12">
          <validation-provider slim :name="$t('global.date')" rules="required" v-slot="{ errors }">
            <date-time-picker
              v-model="innerValue.dateTime"
              :max="$moment().format('YYYY-MM-DDTHH:mm:ss')"
              data-vv-name="dateTime"
              :label="$t('global.date')"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <validation-provider slim :name="$t('global.description')" rules="max:512" v-slot="{ errors }">
            <v-textarea
              v-model="innerValue.description"
              rows="1"
              :label="$t('global.description')"
              color="tertiary"
              :counter="512"
              :error-messages="errors"
            />
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <file-input v-model="innerValue.file" ref="fileInput" />
        </v-col>
      </v-row>
    </v-container>
  </validation-observer>
</template>

<script>
  import { PaymentType } from "../model/Expense";

  export default {
    name: "ExpenseItemForm",
    props: {
      value: Object
    },
    components: {
      CurrencyPicker: () => import("@/components/selectpickers/currency-picker"),
      ExpenseTypePicker: () => import("./ExpenseTypePicker"),
      FileInput: () => import("./FileInput"),
      DateTimePicker: () => import("@/components/global/DateTimePicker")
    },
    data: vm => ({
      paymentTypes: vm.$helpers.fromEnumToArray(PaymentType)
    }),
    computed: {
      innerValue: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val);
        }
      },
      isCurrencyCodeDisabled() {
        return !!this.$helpers.get(this.innerValue, "expenseTypeObject.maxAmountWithoutApproval.code");
      }
    },
    watch: {
      "innerValue.expenseTypeObject"(expenseType) {
        if (expenseType) {
          this.innerValue.expenseType = expenseType.id;
          this.innerValue.requested.code =
            this.$helpers.get(expenseType, "maxAmountWithoutApproval.code") || this.innerValue.requested.code;
        } else {
          this.innerValue.expenseType = null;
          this.innerValue.requested.code = null;
        }
      }
    },
    methods: {
      resetForm() {
        this.$refs.validator.reset();
        this.expenseType = null;
      }
    }
  };
</script>
